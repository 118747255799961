:root{
    --twikVibe-dark-theme-bg-color: #1C1B1B;
    --twikVibe-light-theme-bg-color: #fff;
    --twikVibe-brand-color: #2B9FFF; 
    --twikVibe-brand-hover-color: #1A82D6;
    --twikVibe-darker-brand-color: #8dc2ee; 
    --twikVibe-dark-btn-color: #252424;
    --twikVibe-transparent-color: rgba(43, 159, 255, 0.3);
    --twikVibe-light-btn-color: ivory;
    --twikVibe-light-theme-color: #fff;
    --twikVibe-dark-theme-color: #252424;
    --twikVibe-dark-text-color: #1C1B1B;
    --twikVibe-light-text-color: #fff;
    --twikVibe-red-danger-zone-bg-color: rgba(255, 0, 0, 0.8);
    --twikVibe-red-danger-zone-btn-color: #ed1515;
    --twikVibe-bold-font: "twikVibe-font-bold";
    --twikVibe-regular-font: "twikVibe-font-regular";
    --twikVibe-dark-grey-bg: #222222;
    --twikVibe-light-grey-bg: #F3F4F4;
    --twikvibe-grey-color: #92928a;
    --twikvibe-white-color: #fff;
    --twikvibe-transparent-white-color: rgba(225, 225, 225, 0.8);
    --twikvibe-transparent-black-color: rgba(0,0,0,0.5);
    --twikVibe--light-transparent-black-color: rgba(0,0,0,0.3);
    --twikVibe--darker-transparent-black-color: rgba(0,0,0,0.4);
}

[data-theme="dark"]{
    --twikVibe-dark-theme-bg-color: #1C1B1B;
    --twikVibe-light-theme-bg-color: #fff;
    --twikVibe-dark-btn-color: #252424;
    --twikVibe-light-btn-color: #fff;
    --twikVibe-light-theme-color: #fff;
    --twikVibe-dark-theme-color: #252424;
    --twikVibe-dark-text-color: #1C1B1B;
    --twikVibe-light-text-color: #fff;
    --twikVibe-dark-grey-bg: #222222;
    --twikVibe-light-grey-bg:  #F3F4F4;
    --twikVibe--light-transparent-black-color: rgba(0,0,0,0.1);
    --twikVibe--darker-transparent-black-color: rgba(0,0,0,0.2);
}

[data-theme="light"]{
    --twikVibe-dark-theme-bg-color: #fff;
    --twikVibe-light-theme-bg-color: #1C1B1B;
    --twikVibe-dark-btn-color: #fff;
    --twikVibe-light-btn-color: #252424;
    --twikVibe-light-theme-color: #252424;
    --twikVibe-dark-theme-color: #fff;
    --twikVibe-dark-text-color: #fff;
    --twikVibe-light-text-color: #1C1B1B;
    --twikVibe-dark-grey-bg: #F3F4F4;
    --twikVibe-light-grey-bg: #222222;
    --twikVibe--light-transparent-black-color: rgba(0,0,0,0.2);
    --twikVibe--darker-transparent-black-color: rgba(0,0,0,0.1);
}


*{
    padding: 0;
    margin: 0;
    transition: all .2s ease-in-out;
    outline:none !important;
    border: none;
    /* font-size: 16px; */
}

@font-face {
    font-family:"twikVibe-font-bold";
    src: url("./assets/fonts/LiberationSans-Bold.ttf");
}

@font-face {
    font-family:"twikVibe-font-regular";
    src: url("./assets/fonts/LiberationSans-Regular.ttf");
}





body {
    scroll-behavior: smooth;
    /* background-color: var(--twikVibe-dark-theme-bg-color);
    color: var(--twikVibe-light-text-color); */
    font-family: var(--twikVibe-regular-font);
}

/* Dark mode styles */
body[data-theme="dark"] {
    background-color: var(--twikVibe-dark-theme-bg-color);
    color: var(--twikVibe-light-text-color);
}

/* Light mode styles */
body[data-theme="light"] {
    background-color: var(--twikVibe-dark-theme-bg-color);
    color: var(--twikVibe-light-text-color);
}


.transparent_click{
    text-decoration: none;
    outline: none;
}

.inactive{
    color: var(--twikvibe-grey-color);
}

.active{
    /* background-color: var(--twikVibe-dark-grey-bg); */
    color: var(--twikVibe-light-text-color);
    text-decoration: none;
    border-radius: 5px;
}

.app{
    display: flex;
}

@media screen and (max-width: 600px){
    .app{
        display: block;
    }
}
